import BigNumber from "bignumber.js";
import interpolate from "linear-interpolator";
import COMPARISONS from "./data/comparisons.json";
import INCOME_CENTILES from "./data/income_centiles.json";
import PPP_CONVERSION from "./data/ppp_conversion.json";
export { COMPARISONS };
var interpolateIncomeCentileByAmountInterpolator = interpolate(INCOME_CENTILES.map(function(centile) {
    return [
        centile.daily_2017_dollars * 365,
        centile.percentile, 
    ];
}));
var interpolateIncomeCentileByAmount = function(amount) {
    return new BigNumber(interpolateIncomeCentileByAmountInterpolator(amount)).decimalPlaces(1).toNumber();
};
export var MEDIAN_INCOME = INCOME_CENTILES.find(function(param) {
    var percentile = param.percentile;
    return percentile === 50;
}).daily_2017_dollars * 365;
// calculate how to adjust for household size, the dataset we currently use
// just divides by the number of people in the household
var householdEquivalizationFactor = function(param) {
    var _adults = param.adults, adults = _adults === void 0 ? 1 : _adults, _children = param.children, children = _children === void 0 ? 0 : _children;
    return Number(adults) + Number(children);
};
var internationalizeIncome = function(income, countryCode) {
    return new BigNumber(income).dividedBy(PPP_CONVERSION[countryCode]).decimalPlaces(2).toNumber();
};
// equivalises an income to a particular household composition
var equivalizeIncome = function(income, household) {
    return new BigNumber(income).dividedBy(householdEquivalizationFactor(household)).decimalPlaces(2).toNumber();
};
// calculate how many times the median income a person's income is
var getMedianMultiple = function(income) {
    return new BigNumber(income).dividedBy(MEDIAN_INCOME).decimalPlaces(1).toNumber();
};
// the main event. takes an income, country code and household composition,
// and returns a bunch of useful stats for making comparisons to the
// rest of the world
export var calculateIncomePercentile = function(param) {
    var income = param.income, countryCode = param.countryCode, numAdults = param.numAdults, numChildren = param.numChildren;
    var internationalizedIncome = internationalizeIncome(income, countryCode);
    var equivalizedIncome = equivalizeIncome(internationalizedIncome, {
        adults: Number(numAdults),
        children: Number(numChildren)
    });
    var incomeCentile = Math.min(99.9, interpolateIncomeCentileByAmount(equivalizedIncome));
    var incomeTopPercentile = new BigNumber(100).minus(incomeCentile).decimalPlaces(1).toNumber();
    var medianMultiple = getMedianMultiple(equivalizedIncome);
    return {
        internationalizedIncome: internationalizedIncome,
        equivalizedIncome: equivalizedIncome,
        incomeCentile: incomeCentile,
        incomeTopPercentile: incomeTopPercentile,
        medianMultiple: medianMultiple
    };
};
export var getDonationComparisonAmount = function(donationAmount, comparison) {
    return (donationAmount / comparison.cost).toFixed(comparison.fractionDigits);
};
